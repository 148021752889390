import React, { Component } from 'react';
import TermsOfUseBody from '../Components/TermsOfServices/TermsOfUseBody';
export class TermsOfServices extends Component {
    render() {
        

        return (
            <div>
                <TermsOfUseBody></TermsOfUseBody>
            </div>
        );
    }
}

export default TermsOfServices;