import './App.css';
import {HomePage } from './Pages/HomePage';
import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';
import { AboutUs } from './Pages/AboutUs';
import {Navigation} from './Components/Navigation';
import {Footer} from './Components/Footer';
import {Careers} from './Pages/Careers';
import {ContactUs} from './Pages/ContactUs';
import {PrivacyPolicy} from './Pages/PrivacyPolicy'
import { RefundPolicy } from "./Pages/RefundPolicy";
import {  Services} from "./Pages/Services";
import {TermsOfServices  } from "./Pages/TermsOfServices";
import {Blog} from './Pages/Blog';
import {GetQuote} from './Pages/GetQuote';
import FeedbackForm from './Components/FeedbackForm';
import {Page404} from './Pages/Page404';
import {Pay} from './Pages/Pay';
import Construction from './Pages/Construction';
import ScrollToTop  from './Components/ScrollToTop';

function App() {
  
  
  return (
    
<>

 
<Router >
    <ScrollToTop/>

    <div className="App">
    <Navigation></Navigation>

    <Switch>

    <Route path="/" exact component={HomePage}/>
    <Route path="/about" exact component={AboutUs}/>
    <Route path="/careers" exact component={Careers}/>
    <Route path="/contact" exact component={ContactUs}/>
    <Route path="/privacy-policy" exact component={PrivacyPolicy}/>
    <Route path="/refund-policy" exact component={RefundPolicy}/>
    <Route path="/services" exact component={Services}/>
    <Route path="/terms-of-services"  exact component={TermsOfServices}/>
    <Route path="/blog" exact component={Blog}/>
    <Route path="/get-quote" exact component={GetQuote}/>
    <Route path="/feedback" exact component={FeedbackForm}/>
    <Route path="/pay" exact component={Pay}/>
    <Route path="/construction" exact component={Construction}/>
    
    <Route component={Page404}/>
   
    </Switch>
    </div>
    <Footer></Footer>
    </Router>
    
    </>
  );
}

export default App;
