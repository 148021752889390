import React, { Component } from 'react';
import {EmailSubscribeForm} from '../EmailSubscribeForm';
import { Link } from 'react-router-dom';
export class ServicesBody extends Component {
    render() {
        return (
            <div>
                {/*Start Header */}
        <div id="header" />
        {/*End Header */}
        {/*Breadcrumb Area*/}
        <section className="breadcrumb-area banner-2">
          <div className="text-block">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 v-center">
                  <div className="bread-inner">
                    <div className="bread-menu">
                      <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/services">Services</Link></li>
                      </ul>
                    </div>
                    <div className="bread-title">
                      <h2>Our Services</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Breadcrumb Area*/}
        {/*Start Design Service*/}
        <section className="service-block bg-lights pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="common-heading">
                  <span>Service</span>
                  <h2 className="mb30">Design Service</h2>
                </div>
              </div>
            </div>
            <div className="row upset link-hover">
              <div className="col-lg-4 col-sm-6 mt30">
                <div className="s-block">
                  <div className="s-card-icon"><img src="https://static-cdn.justinclicks.com/images/icons/logo-and-branding.svg" alt="service" className="img-fluid" /></div>
                  <h4>Logo &amp; branding</h4>
                  <Link to="/get-quote">Learn More <i className="fas fa-chevron-right fa-icon" /></Link>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt30">
                <div className="s-block">
                  <div className="s-card-icon"><img src="https://static-cdn.justinclicks.com/images/icons/website-design.svg" alt="service" className="img-fluid" /></div>
                  <h4>Website design</h4>
                  <Link to="/services-web-design-and-development">Learn More <i className="fas fa-chevron-right fa-icon" /></Link>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt30">
                <div className="s-block">
                  <div className="s-card-icon"><img src="https://static-cdn.justinclicks.com/images/icons/app.svg" alt="service" className="img-fluid" /></div>
                  <h4>Mobile app design</h4>
                  <Link to="/services-mobile-app">Learn More <i className="fas fa-chevron-right fa-icon" /></Link>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt30">
                <div className="s-block">
                  <div className="s-card-icon"><img src="https://static-cdn.justinclicks.com/images/icons/graphic.svg" alt="service" className="img-fluid" /></div>
                  <h4>Graphic/print design</h4>
                  <Link to="/get-quote">Learn More <i className="fas fa-chevron-right fa-icon" /></Link>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt30">
                <div className="s-block">
                  <div className="s-card-icon"><img src="https://static-cdn.justinclicks.com/images/icons/video.svg" alt="service" className="img-fluid" /></div>
                  <h4>Video production</h4>
                  <Link to="/get-quote">Learn More <i className="fas fa-chevron-right fa-icon" /></Link>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt30">
                <div className="s-block">
                  <div className="s-card-icon"><img src="https://static-cdn.justinclicks.com/images/icons/design-icon.svg" alt="service" className="img-fluid" /></div>
                  <h4>Graphic Design</h4>
                  <Link to="/get-quote">Learn More <i className="fas fa-chevron-right fa-icon" /></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Design Service*/}
        {/*Start Development Service*/}
        <section className="service-block pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="common-heading">
                  <span>Service</span>
                  <h2 className="mb30">Development Service</h2>
                </div>
              </div>
            </div>
            <div className="row upset link-hover">
              <div className="col-lg-4 col-sm-6 mt30">
                <div className="s-block">
                  <div className="s-card-icon"><img src="https://static-cdn.justinclicks.com/images/icons/content.svg" alt="service" className="img-fluid" /></div>
                  <h4>Content Managment</h4>
                  <Link to="/wordpress">Learn More <i className="fas fa-chevron-right fa-icon" /></Link>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt30">
                <div className="s-block">
                  <div className="s-card-icon"><img src="https://static-cdn.justinclicks.com/images/icons/webdevelopment.svg" alt="service" className="img-fluid" /></div>
                  <h4>Website Development</h4>
                  <Link to="/services-web-design-and-development">Learn More <i className="fas fa-chevron-right fa-icon" /></Link>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt30">
                <div className="s-block">
                  <div className="s-card-icon"><img src="https://static-cdn.justinclicks.com/images/icons/app.svg" alt="service" className="img-fluid" /></div>
                  <h4>Mobile app Development</h4>
                  <Link to="/services-mobile-app">Learn More <i className="fas fa-chevron-right fa-icon" /></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Development Service*/}
        {/*Start Digital Marketing*/}
        <section className="service-block bg-lights pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="common-heading">
                  <span>Service</span>
                  <h2 className="mb30">Digital Marketing Service</h2>
                </div>
              </div>
            </div>
            <div className="row upset link-hover">
              <div className="col-lg-4 col-sm-6 mt30">
                <div className="s-block">
                  <div className="s-card-icon"><img src="https://static-cdn.justinclicks.com/images/icons/seo.svg" alt="service" className="img-fluid" /></div>
                  <h4>Search Engine Optimization</h4>
                  <Link to="/services-digital-marketing">Learn More <i className="fas fa-chevron-right fa-icon" /></Link>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt30">
                <div className="s-block">
                  <div className="s-card-icon"><img src="https://static-cdn.justinclicks.com/images/icons/smo.svg" alt="service" className="img-fluid" /></div>
                  <h4>Social Media Optimization</h4>
                  <Link to="/services-digital-marketing">Learn More <i className="fas fa-chevron-right fa-icon" /></Link>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt30">
                <div className="s-block js-tilt">
                  <div className="s-card-icon"><img src="https://static-cdn.justinclicks.com/images/icons/money.svg" alt="service" className="img-fluid" /></div>
                  <h4>Branding</h4>
                  <Link to="/services-digital-marketing">Learn More <i className="fas fa-chevron-right fa-icon" /></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Digital Marketing*/}
        {/*Start CTA*/}
        <section className="cta-area pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading">
                  <span>Let's work together</span>
                  <h2>We Love to Listen to Your Requirements</h2>
                  <Link to="/get-quote" className="btn-outline">Estimate Project <i className="fas fa-chevron-right fa-icon" /></Link>
                  <p className="cta-call">Or WhatsApp us now <a target="_blank" rel="noreferrer" href="https://wa.me/917972789646"> +91-7972789646</a></p>
                </div>
              </div>
            </div>
          </div>
          <div className="shape shape-a1"><img src="https://static-cdn.justinclicks.com/images/shape/shape-3.svg" alt="shape" /></div>
          <div className="shape shape-a2"><img src="https://static-cdn.justinclicks.com/images/shape/shape-4.svg" alt="shape" /></div>
          <div className="shape shape-a3"><img src="https://static-cdn.justinclicks.com/images/shape/shape-13.svg" alt="shape" /></div>
          <div className="shape shape-a4"><img src="https://static-cdn.justinclicks.com/images/shape/shape-11.svg" alt="shape" /></div>
        </section>
        {/*End CTA*/}
        {/*Start Footer*/}
        <footer>
          <div className="footer-row1">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="email-subs">
                    <h3>Get Our Product Updates!</h3>
                  </div>
                </div>
                <div className="col-lg-6 v-center">
                <EmailSubscribeForm></EmailSubscribeForm>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-svg">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 80" style={{enableBackground: 'new 0 0 1920 80'}} xmlSpace="preserve">
              <path className="st0" d="M0,27.2c589.2,129.4,1044-69,1920,31v-60H3.2L0,27.2z" />
            </svg>
          </div>
          {/*footer*/}
          <div id="footer" />
        </footer>
            </div>
        );
    }
}

export default ServicesBody;