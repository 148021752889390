import React, { Component } from 'react';
import {EmailSubscribeForm} from '../EmailSubscribeForm';
import { Link } from 'react-router-dom';
export class AboutBody extends Component {
    render() {
        return (
            <div>
                {/*Start Header */}
        <div id="header" />
        {/*End Header */}
        {/*Breadcrumb Area*/}
        <section className="breadcrumb-area banner-1">
          <div className="text-block">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 v-center">
                  <div className="bread-inner">
                    <div className="bread-menu">
                      <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">About Us</Link></li>
                      </ul>
                    </div>
                    <div className="bread-title">
                      <h2>About Company</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Breadcrumb Area*/}
        {/*Start About*/}
        <section className="about-agency pad-tb block-1">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 v-center">
                <div className="about-image">
                  <img src="https://static-cdn.justinclicks.com/images/about/company-about.png" alt="about us" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="common-heading text-l ">
                  <span>About Company</span>
                  <h2>About Company</h2>
                  <p>
                    We are software company. we provide below <Link to="/services">Services.</Link><br />
                  </p><ul style={{lineHeight: '50px'}}>
                    <li><Link to="/services-mobile-app">Android Apps</Link></li>
                    <li><Link to="/services-web-design-and-development">Web Development</Link></li>
                    <li><Link to="/services-desktop-apps-software">Desktop Apps</Link></li>
                    <li><Link to="/wordpress">WordPress</Link></li>
                    <li><Link to="/services-digital-marketing">Digital Marketing</Link></li>
                    <li><Link to="/educational-projects">Edu. Projects</Link></li>
                  </ul>  
                  <br />
                  <br /><h3>Our Scores</h3>
                  <p />
                </div>
                <div className="row in-stats small about-statistics">
                  <div className="col-lg-4 col-sm-4">
                    <div className="statistics">
                      <div className="statnumb counter-number">
                        <span className="counter">42</span>
                        <p>Happy Clients</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4">
                    <div className="statistics">
                      <div className="statnumb">
                        <span className="counter">95</span><span>k</span>
                        <p>Hours Worked</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4">
                    <div className="statistics mb0">
                      <div className="statnumb counter-number">
                        <span className="counter">480</span>
                        <p>Projects Done</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End About*/}
        {/*Start why-choose*/}
        <section className="why-choose pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="common-heading">
                  <span>We Are Awesome</span>
                  <h2 className="mb30">Why Choose Us</h2>
                </div>
              </div>
            </div>
            <div className="row upset">
              <div className="col-lg-3 col-sm-6 mt30">
                <div className="s-block">
                  <div className="s-card-icon"><img src="https://static-cdn.justinclicks.com/images/icons/research.svg" alt="service" className="img-fluid" /></div>
                  <h4>Reasearch and Analysis</h4>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 mt30">
                <div className="s-block">
                  <div className="s-card-icon"><img src="https://static-cdn.justinclicks.com/images/icons/chat.svg" alt="service" className="img-fluid" /></div>
                  <h4>Negotiation and power</h4>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 mt30">
                <div className="s-block">
                  <div className="s-card-icon"><img src="https://static-cdn.justinclicks.com/images/icons/monitor.svg" alt="service" className="img-fluid" /></div>
                  <h4>Creative and innovative solutions</h4>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 mt30">
                <div className="s-block">
                  <div className="s-card-icon"><img src="https://static-cdn.justinclicks.com/images/icons/trasparency.svg" alt="service" className="img-fluid" /></div>
                  <h4>Trasparency and ease of work</h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End why-choose*/}
        {/*Start Footer*/}
        <footer>
          <div className="footer-row1">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="email-subs">
                    <h3>Get Our Product Updates!</h3>
                  </div>
                </div>
                <div className="col-lg-6 v-center">
                <EmailSubscribeForm></EmailSubscribeForm>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-svg">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 80" style={{enableBackground: 'new 0 0 1920 80'}} xmlSpace="preserve">
              <path className="st0" d="M0,27.2c589.2,129.4,1044-69,1920,31v-60H3.2L0,27.2z" />
            </svg>
          </div>
          {/*footer*/}
          <div id="footer" />
        </footer>
            </div>
        );
    }
}

export default AboutBody;