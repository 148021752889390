import React, { Component } from 'react';
import {RefundPolicyBody} from '../Components/RefundPolicy/RefundPolicyBody';
export class RefundPolicy extends Component {
    render() {
        return (
            <div>
               <RefundPolicyBody></RefundPolicyBody>
            </div>
        );
    }
}

export default RefundPolicy;