import React, { Component } from "react";
import NetlifyForm from "react-netlify-form";

export class EmailSubscribeForm extends Component {
  render() {
    return (
      <div className="email-subs-form">
        <NetlifyForm name="Email Subscribers">
          {({ loading, error, success }) => (
            <div>
              {loading && <div>Loading...</div>}
              {error && (
                <div>
                  Your information was not sent. Please try again later.
                </div>
              )}
              {success && <div>Thank you for contacting us!</div>}
              {!loading && !success && (
                <div>
                  <div className="email-subs-form">
                    <input
                      type="email"
                      placeholder=" Your Email Address"
                      name="email"
                      required
                    />
                    <button
                      type="submit"
                      name="submit"
                      className="lnk btn-main bg-btn"
                    >
                      Subscribe <i className="fas fa-chevron-right fa-icon" />
                      <span className="circle" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </NetlifyForm>
      </div>
    );
  }
}

export default EmailSubscribeForm;
