import React, { Component } from 'react';
import {GetQuoteBody} from '../Components/GetQuote/GetQuoteBody';
import { Helmet } from 'react-helmet';


export class GetQuote extends Component {
    render() {
        return (
            <div>
                <Helmet>
                <title>Get Quote | JustInClicks.com </title>
        <meta name="description" content="JustInClicks.com is best web development, desktop and android app development company. Get a quote in few minutes  or contact us : info@justinclicks.com" />
        <meta name="keywords" content="web design company, web development, website design, web design services, digital marketing company, internet marketing company, web development services pune, BEST seo company pune, seo firm in pune, seo agency in pune,  website designing services in pune, digital marketing Pune,  android app development company,android app developer,desktop app developer,best android app developer pune,best android developer in Pune,Pune Website developer,Free website development" />
        <meta name="author" content="JustInClicks.com" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="theme-color" content="#c7ecff" />
            </Helmet>
           <GetQuoteBody></GetQuoteBody>
            </div>
        );
    }
}

export default GetQuote;