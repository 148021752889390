import React, { Component } from 'react';
import { Link } from 'react-router-dom';
export class RefundPolicyBody extends Component {
    render() {
        return (
          <>
   {/*Start Header */}
   <div id="header" />
        {/*End Header */}
        {/*Breadcrumb Area*/}
        <section className="breadcrumb-area banner-6">
          <div className="text-block">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 v-center">
                  <div className="bread-inner">
                    <div className="bread-menu">
                      <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/refund-policy">Refund Policy</Link></li>
                      </ul>
                    </div>
                    <div className="bread-title">
                      <h2>Refund Policy</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
            <section className="pad-tb ">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="common-heading text-left">
                
              </div>
            </div>
          </div>
          <div className="row mt40">
          
            <div className="col-md-12">
            <p className="mb30 font-15">
                  
                  Since we deal in online software products and it's a digital service, we don't offer a refund & return. We request you to use our free trial first, available for people with Indian mobile numbers. You can also watch our YouTube channel and Documentation guide to get to know about Software, then only please choose our product if it suits your business, We don't accept any refund request as we are in digital goods and services. for any query, please contact us

<h5>Sale items</h5>
unfortunately, sale items cannot be returned or refunded.

<h5>Exchanges</h5>
We do not allow to exchange subscriptions between users, In case we found similar activity we will ban you from our platform.

<h5>Gifts</h5>
As of now, we don't have any feature of gifting a Subscription.

<h5>Shipping</h5>
On our website, everything is online. We don't provide a CD or DVD of software.

<h5>Double Purchase</h5>
In case of double payment, due to any reason, you will get your 100% money back. It may take 14 working days to reflect in your bank account after your request is approved.

We keep our pricing very transparent and honest.

    
                  </p>         </div>
          </div>
        </div>
      </section>
              

          </>
           
            
        );
    }
}

export default RefundPolicyBody;