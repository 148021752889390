import React, { Component } from 'react';
import { Link } from 'react-router-dom';
export class Page404 extends Component {
    render() {
        return (
            <div>
                 {/*Start 404 Error*/}
      <section className="error bg-gradient pad-tb">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="layer-div">
                <div className="error-block">
                  <h1>Page not Found</h1>
                  <div className="images">
                    <img src="https://static-cdn.justinclicks.com/images/shape/error-page.png" alt="error page" className="img-fluid" />
                   <br /> <Link to="/" className="btn-outline">Back to Home</Link>

                  </div>

                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br /><br /><br />
            </div>
        );
    }
}

export default Page404;