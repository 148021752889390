import React, { Component } from "react";
import { Link } from "react-router-dom";
export class Navigation extends Component {
  render() {
    return (
      <div>
        <header className="top-header">
          <nav className="navbar navbar-expand-lg navbar-light justify-content-right navbar-mobile fixed-top">
            <div className="container">
            <li className="navbar-brand ">
                    <Link className="nav-link " to="/"> <h4>JustInClicks</h4></Link>
                  </li>
            
              <button
                className="navbar-toggler mobile-none"
                type="button"
                data-toggle="collapse"
                data-target="#navbar4"
                aria-controls="navbar4"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div
                className="collapse navbar-collapse animate slideIn mobile-none"
                id="navbar4"
              >
                <ul className="mr-auto" />
                <ul className="navbar-nav d-menu">
                  <li className="nav-item ">
                    <Link className="nav-link " to="/"> Home</Link>
                  </li>
                  <li className="nav-item ">
                    <Link className="nav-link " to="/about"> About</Link>
                  </li>
                  <li className="nav-item ">
                    <Link
                       className="nav-link" to="/services"> Services</Link>
                  </li>

                  
                  
                 
                  <li className="nav-item ">
                    <Link className="nav-link " to="/blog"> Blog</Link>
                  </li>
                  <li className="nav-item ">
                    <Link className="nav-link " to="/contact"> Contact</Link>
                  </li>
                  <li className="nav-item ">
                    <Link className="nav-link custom-btn lnk btn-main bg-btn" to="/get-quote"> Get Quote</Link>
                  </li>
                 
                </ul>
              </div>
              <div className="mobile-menu">
                <ul className="mob-nav">
                <li className="nav-item ">
                    <Link className="nav-link custom-btn lnk btn-main bg-btn" to="/get-quote"> Get Quote<span className="circle" /></Link>
                  </li>
               
                  <li>
                  <Link
                      className="nav-link mobilemenu"
                      to="#,enu"
                      data-toggle="modal"
                      data-target="#menu"
                    >
                      <i className="fas fa-bars" />
                    </Link>
                  
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </div>
    );
  }
}

export default Navigation;
