import React, { Component } from 'react';
import { Link } from 'react-router-dom';
export class GetQuoteBody extends Component {
    render() {
        return (
            <div>
                 {/*Start Header */}
        <div id="header" />
        {/*End Header */}
        <section className="bg-gradient pad-tb">
          <br /><br />
          <iframe title="chat" src="https://links.collect.chat/5d2eb4a6e754a7016050df00" width="100%" height={600} frameBorder={0}>
          </iframe>
        </section>
        {/*Start CTA*/}
        <section className="cta-area pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading">
                  <span>Don't like Bot?</span>
                  <h2>Talk with human. We Love to Listen to Your Requirements</h2>
                  <Link to="/contact" className="btn-outline">Contact us <i className="fas fa-chevron-right fa-icon" /></Link>
                  <p className="cta-call">Or WhatsApp us now <a target="_blank" rel="noreferrer" href="https://wa.me/917972789646"> +91-7972789646</a></p>
                </div>
              </div>
            </div>
          </div>
          <div className="shape shape-a1"><img src="https://static-cdn.justinclicks.com/images/shape/shape-3.svg" alt="shape" /></div>
          <div className="shape shape-a2"><img src="https://static-cdn.justinclicks.com/images/shape/shape-4.svg" alt="shape" /></div>
          <div className="shape shape-a3"><img src="https://static-cdn.justinclicks.com/images/shape/shape-13.svg" alt="shape" /></div>
          <div className="shape shape-a4"><img src="https://static-cdn.justinclicks.com/images/shape/shape-11.svg" alt="shape" /></div>
        </section>
        {/*End CTA*/}
            </div>
        );
    }
}

export default GetQuoteBody;