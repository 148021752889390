import React, { Component } from 'react';
import { Link } from 'react-router-dom';
export class Careers extends Component {
    render() {
        return (
            <div>
                 <div>
                <>
   {/*Start Header */}
   <div id="header" />
        {/*End Header */}
        {/*Breadcrumb Area*/}
        <section className="breadcrumb-area banner-6">
          <div className="text-block">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 v-center">
                  <div className="bread-inner">
                    <div className="bread-menu">
                      <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/careers">Careers</Link></li>
                      </ul>
                    </div>
                    <div className="bread-title">
                      <h2>Careers</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
            <section className="pad-tb ">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="common-heading text-left">
                
              </div>
            </div>
          </div>
          <div className="row mt40">
          
            <div className="col-md-12">
                  We're currently working on this page.
                      </div>
          </div>
        </div>
      </section>
              

          </>
            </div>
            </div>
        );
    }
}

export default Careers;