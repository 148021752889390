import React, { Component } from 'react';
import { Link } from 'react-router-dom';
export class Pay extends Component {
    render() {
        return (
            <div>
                <>
   {/*Start Header */}
   <div id="header" />
        {/*End Header */}
        {/*Breadcrumb Area*/}
        <section className="breadcrumb-area banner-6">
          <div className="text-block">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 v-center">
                  <div className="bread-inner">
                    <div className="bread-menu">
                      <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/pay">Payment Methods</Link></li>
                      </ul>
                    </div>
                    <div className="bread-title">
                      <h2>Payment Methods</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
            <section className="pad-tb ">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="common-heading text-left">
                
              </div>
            </div>
          </div>
          <div className="row mt40">
            <div className="col-md-12">
            <div class="col-md-12 mt60">
          <div class="tabs-layout">
            <ul class="nav nav-tabs" id="myTab1" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" id="indian-tab" data-toggle="tab" href="#indian" role="tab" aria-controls="indian" aria-selected="true">Indian</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="uk-tab" data-toggle="tab" href="#uk" role="tab" aria-controls="uk" aria-selected="false">United Kingdom</a>
              </li>
              <li class="nav-item">
                <a class="nav-link " id="usa-tab" data-toggle="tab" href="#usa" role="tab" aria-controls="usa" aria-selected="false">USA</a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent1">
              <div class="tab-pane fade active show" id="indian" role="tabpanel" aria-labelledby="indian-tab">
                <h4>Payment Details</h4>
                <p>
                  <ul>
                    <li>A/c Number : 226001600000050</li>
                    <li>A/c Name   : JUSTINCLICKS.COM</li>
                    <li>IFSC Code : HDFC0CPDCCB</li>
                    
                  </ul>
                </p>
              </div>
              <div class="tab-pane fade" id="uk" role="tabpanel" aria-labelledby="uk-tab">
              <h4>Payment Details</h4>
                <p>
                  <ul>
                    <li>A/c Number : 03757365</li>
                    <li>A/c Name   : Kaustubh Kulkarni</li>
                    <li>Sort Code : 231486</li>
                    <li>Bank Name : Barclays</li>
                  </ul>
                </p>      </div>
              <div class="tab-pane fade  " id="usa" role="tabpanel" aria-labelledby="usa-tab">
              <h4>Payment Details</h4>
                <p>
                  <ul>
                    <li>A/c Number : 4011007011076</li>
                    <li>A/c Name   : Kaustubh Kulkarni</li>
                    <li>Routing (ABA) : 061120084</li>
                    <li>Bank Name : First Century Bank</li>
                  </ul>
                </p>  </div>
            </div>
          </div>
        </div>                      </div>
          </div>
          <br />
          <p>Please make payment of exact amount, if you're not sure about amount, please <Link to='/contact'>contact us</Link></p>

        </div>
        
      </section>
              

          </>
            </div>
        );
    }
}

export default Pay;