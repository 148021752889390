import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import {AboutBody} from '../Components/AboutUs/AboutBody';
export class AboutUs extends Component {
    render() {
        return (
            <div>
            <Helmet>
            <title>About Us | JustInClicks.com</title>
        {/*seo-meta-tag*/}
        <meta charSet="UTF-8" />
        <meta name="description" content="JustInClicks.com is best web development, desktop and android app development company. We provide software and Digital marketing solutions" />
        <meta name="keywords" content="web design company, web development, website design, web design services, digital marketing company, internet marketing company, web development services pune, BEST seo company pune, seo firm in pune, seo agency in pune,  website designing services in pune, digital marketing Pune,  android app development company,android app developer,desktop app developer,best android app developer pune,best android developer in Pune,Pune Website developer,Free website development" />
        <meta name="author" content="JustInClicks.com" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            </Helmet>
            <AboutBody></AboutBody>
            </div>
        );
    }
}

export default AboutUs;