import React, { Component } from 'react';
import {EmailSubscribeForm} from '../EmailSubscribeForm';
import { Link } from 'react-router-dom';
export class HomeBody extends Component {
    render() {
        return (
            <div>
                <div id="header" />
        {/*Start Hero*/}
        <section className="hero-section hero-bg-bg1 bg-gradient">
          <div className="text-block">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 v-center">
                  <div className="header-heading">
                    <h1>Get your business online</h1>
                    <p>Web Development, Android Apps, Desktop Apps, Digital Marketing</p>
                    <Link to="/get-quote" className="btn-main bg-btn lnk">Go Digital <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
                  </div>
                </div>
                <div className="col-lg-6 v-center">
                  <div className="single-image">
                    <img src="https://static-cdn.justinclicks.com/images/hero/hero-image.png" alt="web development" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Hero*/}
        {/*Start Service*/}
        <section className="service-section pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading">
                  <span>Services We Provide</span>
                  <h2 className="mb30">Our Design &amp; Development Services</h2>
                </div>
              </div>
            </div>
            <div className="row upset link-hover shape-bg2">
              <div className="col-lg-3 col-sm-6 mt30">
                <div className="s-block">
                  <div className="s-card-icon"><img src="https://static-cdn.justinclicks.com/images/icons/development.svg" alt="service" className="img-fluid" /></div>
                  <h4>Website Design &amp; Development</h4>
                  <p>We build Websites that builds your business.</p>
                  <Link to="/services-web-design-and-development">Learn More <i className="fas fa-chevron-right fa-icon" /></Link>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 mt30">
                <div className="s-block">
                  <div className="s-card-icon"><img src="https://static-cdn.justinclicks.com/images/icons/app.svg" alt="service" className="img-fluid" />
                  </div>
                  <h4>Mobile App Development</h4>
                  <p>We will develop best app for you.</p>
                  <Link to="/services-mobile-app">Learn More <i className="fas fa-chevron-right fa-icon" /></Link>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 mt30">
                <div className="s-block">
                  <div className="s-card-icon"><img src="https://static-cdn.justinclicks.com/images/icons/branding.svg" alt="service" className="img-fluid" />
                  </div>
                  <h4>Desktop App Development</h4>
                  <p>Use our Softwares to Manage your business</p>
                  <Link to="/services-desktop-apps-software">Learn More <i className="fas fa-chevron-right fa-icon" /></Link>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 mt30">
                <div className="s-block mb0">
                  <div className="s-card-icon"><img src="https://static-cdn.justinclicks.com/images/icons/marketing.svg" alt="service" className="img-fluid" /></div>
                  <h4>Digital Marketing Service</h4>
                  <p>Grow your business using our Digital Services.</p>
                  <Link to="/services-digital-marketing">Learn More <i className="fas fa-chevron-right fa-icon" /></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Service*/}
        {/*Start statistics*/}
        <div className="statistics-section bg-gradient pad-tb">
          <div className="container">
            <div className="row small t-ctr">
              <div className="col-lg-3 col-sm-6">
                <div className="statistics">
                  <div className="statistics-img">
                    <img src="https://static-cdn.justinclicks.com/images/icons/deal.svg" alt="happy" className="img-fluid" />
                  </div>
                  <div className="statnumb">
                    <span className="counter">42</span>
                    <p>Happy Clients</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="statistics">
                  <div className="statistics-img">
                    <img src="https://static-cdn.justinclicks.com/images/icons/computers.svg" alt="project" className="img-fluid" />
                  </div>
                  <div className="statnumb counter-number">
                    <span className="counter">480</span><span />
                    <p>Projects Done</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="statistics">
                  <div className="statistics-img">
                    <img src="https://static-cdn.justinclicks.com/images/icons/worker.svg" alt="work" className="img-fluid" />
                  </div>
                  <div className="statnumb">
                    <span className="counter">95</span><span>k</span>
                    <p>Hours Worked</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="statistics mb0">
                  <div className="statistics-img">
                    <img src="https://static-cdn.justinclicks.com/images/icons/customer-service.svg" alt="support" className="img-fluid" />
                  </div>
                  <div className="statnumb">
                    <span>24/7</span>
                    <p>Support Available</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*End statistics*/}
        {/*Start Portfolio*/}
        <section className="portfolio-section pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading">
                  <span />
                  <h2 className="mb0">Trending Services</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 col-sm-8 mt60">
                <div className="isotope_item hover-scale">
                  <div className="item-image">
                    <Link to="/services-web-design-and-development"><img src="https://static-cdn.justinclicks.com/images/portfolio/image-d.jpg" alt="Portfolio" className="img-fluid" /> </Link>
                  </div>
                  <div className="item-info">
                    <h4><Link to="/services-web-design-and-development">Ecommerce Development</Link></h4>
                    <p>Web Application</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-4 mt60">
                <div className="isotope_item hover-scale">
                  <div className="item-image">
                    <Link to="appointment-booking-app"><img src="https://static-cdn.justinclicks.com/images/portfolio/image-1.jpg" alt="Portfolio" className="img-fluid" /> </Link>
                  </div>
                  <div className="item-info">
                    <h4><Link to="appointment-booking-app">Booking App</Link></h4>
                    <p>Web App, Android</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Portfolio*/}
        {/*Start Clients*/}
        <section className="clients-section pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="common-heading">
                  <span>Our Recent Work</span>
                  <h2>Websites</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="row opl">
                  <ul>
                    <li>
                      <div className="clients-logo"><a rel="noreferrer" target="_blank"  href="https://techupdates.live">
                        </a><h6><a rel="noreferrer" target="_blank" href="https://techupdates.live">TechUpdates.Live
                          </a></h6></div>
                    </li>
                    <li>
                      <div className="clients-logo"><a rel="noreferrer" target="_blank" href="https://www.punavastu.com">
                        </a><h6><a rel="noreferrer" target="_blank" href="https://www.punavastu.com">Punavastu.com
                          </a></h6></div>
                    </li>
                    <li>
                      <div className="clients-logo"><a rel="noreferrer" target="_blank" href="https://www.BeatTheTrade.in">
                        </a><h6><a rel="noreferrer" target="_blank" href="https://www.BeatTheTrade.in">BeatTheTrade.in
                          </a></h6></div>
                    </li>
                    <li>
                      <div className="clients-logo"><a rel="noreferrer" target="_blank" href="https://tusome.africa">
                        </a><h6><a rel="noreferrer" target="_blank" href="https://tusome.africa">Tusome.africa
                          </a></h6></div>
                    </li>
                    <li>
                      <div className="clients-logo"><a rel="noreferrer" target="_blank"  href="https://BeYodha.com">
                        </a><h6><a rel="noreferrer" target="_blank"  href="https://BeYodha.com">BeYodha.com
                          </a></h6></div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Clients*/}
        {/*Start work-category*/}
        <section className="work-category pad-tb">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 v-center">
                <div className="common-heading text-l">
                  <span>Industries we work for</span>
                  <h2>Helping Businesses in All Domains</h2>
                  <p>Successfully delivered digital products in domains.</p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="work-card-set">
                  <div className="icon-set">
                    <div className="work-card cd1">
                      <div className="icon-bg"><img src="https://static-cdn.justinclicks.com/images/icons/icon-1.png" alt="Industries" /></div>
                      <p>Social Networking</p>
                    </div>
                  </div>
                  <div className="icon-set">
                    <div className="work-card cd2">
                      <div className="icon-bg"><img src="https://static-cdn.justinclicks.com/images/icons/icon-2.png" alt="Industries" /></div>
                      <p>Digital Marketing</p>
                    </div>
                  </div>
                  <div className="icon-set">
                    <div className="work-card cd3">
                      <div className="icon-bg"><img src="https://static-cdn.justinclicks.com/images/icons/icon-3.png" alt="Industries" /></div>
                      <p>Ecommerce Development</p>
                    </div>
                  </div>
                  <div className="icon-set">
                    <div className="work-card cd4">
                      <div className="icon-bg"><img src="https://static-cdn.justinclicks.com/images/icons/icon-4.png" alt="Industries" /></div>
                      <p>Video Service</p>
                    </div>
                  </div>
                  <div className="icon-set">
                    <div className="work-card cd5">
                      <div className="icon-bg"><img src="https://static-cdn.justinclicks.com/images/icons/icon-5.png" alt="Industries" /></div>
                      <p>Banking Service</p>
                    </div>
                  </div>
                  <div className="icon-set">
                    <div className="work-card cd6">
                      <div className="icon-bg"><img src="https://static-cdn.justinclicks.com/images/icons/icon-6.png" alt="Industries" /></div>
                      <p>Enterprise Service</p>
                    </div>
                  </div>
                  <div className="icon-set">
                    <div className="work-card cd7">
                      <div className="icon-bg"><img src="https://static-cdn.justinclicks.com/images/icons/icon-7.png" alt="Industries" /></div>
                      <p>Education Service</p>
                    </div>
                  </div>
                  <div className="icon-set">
                    <div className="work-card cd8">
                      <div className="icon-bg"><img src="https://static-cdn.justinclicks.com/images/icons/icon-8.png" alt="Industries" /></div>
                      <p>Tour and Travels</p>
                    </div>
                  </div>
                  <div className="icon-set">
                    <div className="work-card cd9">
                      <div className="icon-bg"><img src="https://static-cdn.justinclicks.com/images/icons/icon-9.png" alt="Industries" /></div>
                      <p>Health Service</p>
                    </div>
                  </div>
                  <div className="icon-set">
                    <div className="work-card cd10">
                      <div className="icon-bg"><img src="https://static-cdn.justinclicks.com/images/icons/icon-10.png" alt="Industries" /></div>
                      <p>Event &amp; Ticket</p>
                    </div>
                  </div>
                  <div className="icon-set">
                    <div className="work-card cd11">
                      <div className="icon-bg"><img src="https://static-cdn.justinclicks.com/images/icons/icon-11.png" alt="Industries" /></div>
                      <p>Restaurant Service</p>
                    </div>
                  </div>
                  <div className="icon-set">
                    <div className="work-card cd12">
                      <div className="icon-bg"><img src="https://static-cdn.justinclicks.com/images/icons/icon-12.png" alt="Industries" /></div>
                      <p>Business Consultant</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End  work-category*/}
       {/*Start Enquire Form*/}
        <section className="enquire-form pad-tb">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="common-heading text-l">
                  <h2 className="mt0">Have Question?<br /> Contact us</h2>
                </div>
                <Link to="/contact" className="btn-main bg-btn lnk">Contact us <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
              </div>
              <div className="col-lg-6 v-center">
                <div className="enquire-image">
                  <img src="https://static-cdn.justinclicks.com/images/contact/hellopic.png" alt="enquire" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Enquire Form*/}
        {/*Start Footer*/}
        <footer>
          <div className="footer-row1">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="email-subs">
                    <h3>Get Our Product Updates!</h3>
                  </div>
                </div>
                <div className="col-lg-6 v-center">
                <EmailSubscribeForm></EmailSubscribeForm>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-svg">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 80" style={{enableBackground: 'new 0 0 1920 80'}} xmlSpace="preserve">
              <path className="st0" d="M0,27.2c589.2,129.4,1044-69,1920,31v-60H3.2L0,27.2z" />
            </svg>
          </div>
          {/*footer*/}
          <div id="footer" />
          {/*footer*/}
        </footer></div> 
        );
    }
}

export default HomeBody;