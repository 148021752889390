import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NetlifyForm from 'react-netlify-form'

export class FeedbackForm extends Component {
    render() {
        return (
            <div>
                <>
   {/*Start Header */}
   <div id="header" />
        {/*End Header */}
        {/*Breadcrumb Area*/}
        <section className="breadcrumb-area banner-6">
          <div className="text-block">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 v-center">
                  <div className="bread-inner">
                    <div className="bread-menu">
                      <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/feedback">Feedback</Link></li>
                      </ul>
                    </div>
                    <div className="bread-title">
                      <h2>Feedback</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
            <section className="pad-tb ">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="common-heading text-left">
                
              </div>
            </div>
          </div>
          <div className="row mt40">
          
          <div className="col-md-8  mx-auto">
            <div className="form-block">
                <NetlifyForm name='Feedback Form'>
  {({ loading, error, success }) => (
    <div>
      {loading &&
        <div>Loading...</div>
      }
      {error &&
        <div>Your information was not sent. Please try again later.</div>
      }
      {success &&
        <div>Thank you for contacting us!</div>
      }
      {!loading && !success &&
        <div>
        <input type="hidden" name="form-name" value="Feedback Form" />
                    <div className="fieldsets row">
                      <div className="col-md-6"><input type="text" placeholder="Full Name" name="Full Name" required /></div>
                      <div className="col-md-6"><input type="email" placeholder="Email Address" name="Email Address" required /></div>
                    </div>
                    <div className="fieldsets row">
                      <div className="col-md-6"><input type="number" placeholder="Contact Number" name="Mobile Number" required /></div>
                      <div className="col-md-6"><input type="text" placeholder="Subject" name="Subject" required /></div>
                    </div>
                    <div className="fieldsets"><textarea placeholder="Feedback" name="Message" required defaultValue={""} /></div>
                    <div className="fieldsets mt20"> <button type="submit" name="submit" className="lnk btn-main bg-btn">Submit <span className="circle" /></button> </div>
                    <p className="trm"><i className="fas fa-lock" />We hate spam, and we respect your privacy and feedback will remain anonymous.</p>
        </div>
      }
    </div>
  )}
</NetlifyForm>
                </div>
                      </div>
</div>
        </div>
      </section>
              

          </>
            </div>
        );
    }
}

export default FeedbackForm;