import React, { Component } from 'react';
import {ServicesBody} from '../Components/Services/ServicesBody';
export class Services extends Component {
    render() {
        return (
            <div>
                <ServicesBody></ServicesBody>
            </div>
        );
    }
}

export default Services;