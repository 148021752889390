import React, { Component } from 'react';
import { Link } from 'react-router-dom';
export class Footer extends Component {
    render() {
        return (
            <div>
               <div>
        <div className="footer-row2">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <h5>Company</h5>
                <ul className="footer-address-list link-hover">
                
                <li><Link className="nav-link " to="/"> Home</Link></li>
                <li><Link className="nav-link " to="/contact"> Contact</Link></li>
                <li><Link className="nav-link " to="/about"> About</Link></li>
                <li><Link className="nav-link " to="/blog"> Blog</Link></li>
                <li><Link className="nav-link " to="/services"> Services</Link></li>
                <li><Link className="nav-link " to="/feedback"> Feedback</Link></li>
                <li><Link className="nav-link " to="/careers"> Careers</Link></li>
                </ul>
              </div>
              <div className="col-lg-3 col-sm-6">
                <h5>Services</h5>
                <ul className="footer-address-list link-hover">
                
                <li><Link className="nav-link " to="/services"> All Services</Link></li>
                <li><Link className="nav-link " to="/construction"> Android Apps</Link></li>
                <li><Link className="nav-link " to="/construction"> Web Development</Link></li>
                <li><Link className="nav-link " to="/construction"> Desktop Apps</Link></li>
                <li><Link className="nav-link " to="/construction"> WordPress</Link></li>
                <li><Link className="nav-link " to="/construction"> Digital Marketing</Link></li>
                </ul>
              </div>
              <div className="col-lg-3 col-sm-6">
                <h5>New Products</h5>
                <ul className="footer-address-list link-hover">
                
                <li><Link className="nav-link " to="/construction"> Appointment System</Link></li>
                <li><Link className="nav-link " to="/construction"> Educational Apps/Sites</Link></li>
                <li><Link className="nav-link " to="/construction"> E-commerce</Link></li>
                <li><Link className="nav-link " to="/construction"> Portfolio</Link></li>
                <li><Link className="nav-link " to="/construction"> QR Code App</Link></li>
              </ul>
              </div>
              <div className="col-lg-3 col-sm-6">
                <h5>Important Links</h5>
                <ul className="footer-address-list link-hover">
                <li><Link className="nav-link " to="/pay"> Pay</Link></li> 
                <li><Link className="nav-link " to="/get-quote"> Get Quote</Link></li>
                <li><Link className="nav-link " to="/construction"> Demos</Link></li>
                <li><Link className="nav-link " to="/terms-of-services"> Terms and Conditions</Link></li>
                <li><Link className="nav-link " to="/refund-policy"> Refund Policy</Link></li>
                <li><Link className="nav-link " to="/privacy-policy"> Privacy Policy</Link></li>
                </ul>
                
         
              </div>
            </div>
          </div>
        </div>
        <hr className="hline" />
        <div className="footer-row3">
          <div className="copyright">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="footer-social-media-icons">
                    <a href="https://www.facebook.com/Official.justinclicks/" target="blank"><i className="fab fa-facebook" /></a>
                    <a href="https://twitter.com/justinclicks" target="blank"><i className="fab fa-twitter" /></a>
                    <a href="https://www.instagram.com/justinclicks.official/" target="blank"><i className="fab fa-instagram" /></a>
                  </div>
                  <div className="footer-">
                    <p>All rights reserved. Copyright © 2018-2021 JustInClicks.com </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*End Footer*/}
        {/*Responsive Mobile Menu*/}
        <div id="menu" className="res-menu p-0 modal fade" role="dialog" aria-labelledby="menu" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content full">
              <div className="modal-header" data-dismiss="modal">
                JustInClicks <i className="far fa-times-circle" />
              </div>
              <div className="menu modal-body">
                <div className="m-nav">
                  <nav className="navbar p0">
                    <ul className="navbar-nav">
                      
                    <li className="nav-item dropdown"><a className="nav-link " href="/"> Home</a></li>
                      <li className="nav-item "><a className="nav-link " href="/about"> About</a></li>
                      <li className="nav-item "><a className="nav-link " href="/services"> Services</a></li>
                      <li className="nav-item "><a className="nav-link " href="/blog"> Blog</a></li>
                      <li className="nav-item "><a className="nav-link " href="/contact"> Contact</a></li>
                      <li className="nav-item "><a className="nav-link custom-btn lnk btn-main bg-btn " href="/get-quote"> Get Quote <span className="circle" /></a></li>


                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
            </div>
        );
    }
}

export default Footer;