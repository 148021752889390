import React, { Component } from 'react';
import {PrivacyPolicyBody} from '../Components/PrivacyPolicy/PrivacyPolicyBody';
export class PrivacyPolicy extends Component {
    render() {
        return (
            <div>
                <PrivacyPolicyBody></PrivacyPolicyBody>
            </div>
        );
    }
}

export default PrivacyPolicy;