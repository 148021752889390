import React, { Component } from 'react';
import NetlifyForm from 'react-netlify-form'
import {EmailSubscribeForm} from '../EmailSubscribeForm';
import { Link } from 'react-router-dom';
export class ContactBody extends Component {
    render() {
        return (
            <div>
                {/*Start Header */}
        <div id="header" />
        {/*End Header */}
        {/*Breadcrumb Area*/}
        <section className="breadcrumb-area banner-6">
          <div className="text-block">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 v-center">
                  <div className="bread-inner">
                    <div className="bread-menu">
                      <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                      </ul>
                    </div>
                    <div className="bread-title">
                      <h2>Contact</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Breadcrumb Area*/}
        {/*Start Enquire Form*/}
        <section className="contact-page pad-tb">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 v-center">
                <div className="common-heading text-l">
                  <span>Contact Now</span>
                  <h2 className="mt0">Have Question? Write a Message</h2>
                </div>
                <div className="form-block">
                <NetlifyForm name='Contact Form'>
  {({ loading, error, success }) => (
    <div>
      {loading &&
        <div>Loading...</div>
      }
      {error &&
        <div>Your information was not sent. Please try again later.</div>
      }
      {success &&
        <div>Thank you for contacting us!</div>
      }
      {!loading && !success &&
        <div>
        <input type="hidden" name="form-name" value="Contact Form" />
                    <div className="fieldsets row">
                      <div className="col-md-6"><input type="text" placeholder="Full Name" name="Full Name" required /></div>
                      <div className="col-md-6"><input type="email" placeholder="Email Address" name="Email Address" required /></div>
                    </div>
                    <div className="fieldsets row">
                      <div className="col-md-6"><input type="number" placeholder="Contact Number" name="Mobile Number" required /></div>
                      <div className="col-md-6"><input type="text" placeholder="Subject" name="Subject" required /></div>
                    </div>
                    <div className="fieldsets"><textarea placeholder="Message" name="Message" required defaultValue={""} /></div>
                    <div className="fieldsets mt20"> <button type="submit" name="submit" className="lnk btn-main bg-btn">Submit <span className="circle" /></button> </div>
                    <p className="trm"><i className="fas fa-lock" />We hate spam, and we respect your privacy.</p>
        </div>
      }
    </div>
  )}
</NetlifyForm>
                </div>
              </div>
              <div className="col-lg-5 v-center">
                <div className="contact-details">
                  <div className="contact-card">
                    <div className="info-card v-center">
                      <span><i className="fas fa-info-circle" /> COVID-19</span>
                      <div className="info-body">
                        <p>Due to COVID-19, we're taking longer than usual.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="email-card mt30">
                    <div className="info-card v-center">
                      <span><i className="fa fa-phone-square" /> Contact:</span>
                      <div className="info-body">
                        <p> 9:30 AM IST to 5:00 PM IST</p>
                        <a href="https://wa.me/917972789646">+91-7972789646</a>
                      </div>
                    </div>
                  </div>
                  <div className="skype-card mt30">
                    <div className="info-card v-center">
                      <span><i className="fas fa-envelope" /> Email:</span>
                      <div className="info-body">
                        <p>We will reply in 24 working hours</p>
                        <Link href="mailto:info@justinclicks.com">info@justinclicks.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Enquire Form*/}
        {/*Start Footer*/}
        <footer>
          <div className="footer-row1">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="email-subs">
                    <h3>Get Our Product Updates!</h3>
                  </div>
                </div>
                <div className="col-lg-6 v-center">
                  <EmailSubscribeForm></EmailSubscribeForm>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-svg">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 80" style={{enableBackground: 'new 0 0 1920 80'}} xmlSpace="preserve">
              <path className="st0" d="M0,27.2c589.2,129.4,1044-69,1920,31v-60H3.2L0,27.2z" />
            </svg>
          </div>
          {/*footer*/}
          <div id="footer" />
          {/*footer*/}
          {/*scroll to top*/}
          <Link id="scrollUp" href="#top" />
        </footer>
            </div>
        );
    }
}

export default ContactBody;